export const markdown = `
山手線の駅ウォーキング企画
みんなと話しながら，わいわい歩きたい方におススメです

年代の近い人と仲良くなりたくて，このイベントを作成しました．
20代30代の皆さん，趣味や好きなことを一緒に共有しませんか？

ほとんどの方が一人参加です！お気軽にご参加ください！
ちょうどよい４人グループをシャッフルしてチームで行動します。

①【集合】
集合場所につきましたら
同じポーズをとりあってお互いを角にしましょう

②【グループ分け】
参加人数から，
1グループ3-4人のグループ分けを行います

③【自己紹介】
簡単に自己紹介しましょう

④【散歩スタート】
各グループで駅周辺を散策しましょう
行き場所は行先ガチャアプリを用いて、集まったメンバで選びます

⑤【解散】
自由解散ですが、2時間目安で区切りましょう。

参加条件
・性別を問わずコミニュケーションが取れる方
・ルールやマナーを守れる方
・営業、勧誘、ナンパ目的でない方
注意事項
・スケジュールは状況に応じて変更になる場合が御座います
・天候不良で開催が困難な時はイベントを中止する場合が御座います
・遅刻をした場合、イベントに参加できなくなります。(その場合の返金はございません)
・時間になり次第、お散歩出発いたします
・イベント中で発生した費用については、ご自分で飲食した分をお支払いください。
禁止事項
・勧誘・営業行為は禁止です
・迷惑行為や他参加者への誹謗中傷も禁止です

イベント中やイベント後も上記の行為があった場合には主催者にご連絡ください
 `;
