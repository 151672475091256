import React from "react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Datas, Route } from "../../../common/types";
import { useParams } from "react-router-dom";

import { Button, TextField, Checkbox, Link } from "@mui/material";

import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";

import ChoiceEki from "./ChoiceEki";

type Props = {
  bgColor: any;
  styletext: any;

  datas: Datas;
  setDatas: Dispatch<SetStateAction<Datas>>;
  routeList: Route[];

  choiceEki: any;
  choiceDate: any;
  choiceTime: any;
  title: any;
  termcheck: any;

  setIsWrote: any;

  createNewPlan: any;
};

const CreateEventConfilm: React.FC<Props> = ({
  bgColor,
  styletext,
  choiceEki,
  choiceDate,
  choiceTime,
  title,
  termcheck,
  setIsWrote,
  createNewPlan,
}) => {
  return (
    <>
      <div
        style={{
          padding: "15px",
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        山手散歩イベントの作成
      </div>
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          lineHeight: "1.6",
          width: "100%",
          maxWidth: "600px",
          margin: "0 auto",
        }}
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginBottom: "20px",
          }}
        >
          <tr>
            <td style={{ padding: "10px", fontWeight: "bold" }}>駅名</td>
            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
              {choiceEki.text}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", fontWeight: "bold" }}>日付</td>
            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
              {choiceDate
                ? choiceDate.format("YYYY-MM-DD")
                : "No date selected"}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", fontWeight: "bold" }}>時間</td>
            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
              {choiceTime ? choiceTime.format("hh:mm A") : "No time selected"}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", fontWeight: "bold" }}>コメント</td>
            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
              {title}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", fontWeight: "bold" }}>利用規約</td>
            <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
              {termcheck
                ? "利用規約に同意します"
                : "利用規約に同意していません。作成できません。"}
            </td>
          </tr>
        </table>

        <div
          style={{
            padding: "15px",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          こちらの内容で作成して宜しければ下記ボタン押下ください
        </div>
        <Button
          style={{
            display: "inline-block",
            padding: "10px 10px",
            fontSize: "18px",
            color: "#fff",
            backgroundColor: termcheck ? bgColor.back06 : bgColor.back05,
            textDecoration: "none",
            borderRadius: "5px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "background-color 0.3s ease",
            width: "100%",
          }}
          disabled={!termcheck}
          onClick={() => {
            const combinedDateTime =
              choiceDate && choiceTime
                ? dayjs(
                    `${choiceDate.format("YYYY-MM-DD")} ${choiceTime.format(
                      "HH:mm"
                    )}`
                  )
                : null;
            const dataDataTime = combinedDateTime?.toDate() || null;
            createNewPlan(choiceEki.text, choiceEki.id, dataDataTime, title);
          }}
        >
          作成
        </Button>
        <Button
          style={{
            display: "inline-block",
            padding: "10px 10px",
            fontSize: "15px",
            color: "#000",
            // backgroundColor: bgColor.back03,
            textDecoration: "none",
            borderRadius: "5px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "background-color 0.3s ease",
            width: "100%",
          }}
          onClick={() => {
            setIsWrote(false);
          }}
        >
          編集する
        </Button>
      </div>
    </>
  );
};
export default CreateEventConfilm;
