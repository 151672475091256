import { useRef } from "react";
import { Box, CircularProgress, Typography, Avatar } from "@mui/material";
import React from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { firebaseApp } from "../firebaseHelper/firebase";

import { updateGatheringPlace } from "../firebaseHelper/FirebaseTransactionHelperGatheringPlace";

type Props = {
  eki_id: string;
  gatheringPlace_id: string;
  imageurl: string;
  setimageurl: any;
};

const ImageSettingGatheringPlace: React.FC<Props> = ({
  eki_id,
  gatheringPlace_id,
  imageurl,
  setimageurl,
}) => {
  const inputRef: any = useRef(null);

  const value = 29;
  const chartcolors = { 0: "#757ce8", 1: "#ff7961" };
  const fileUpload = () => {
    inputRef.current.click();
  };
  //uploadできる画像のバイト数のリミット
  const limitUpdateByte = 300000;

  async function onSubmit(event: any) {
    event.preventDefault();
    if (typeof event.target.files[0] === "undefined") {
      console.log("ファイルが選択されていません");
      return;
    }
    let avatarImage = event.target.files[0];
    //console.log(avatarImage);
    //画像を圧縮
    // new Compressor(avatarImage, {
    //   quality: 0.6,
    // success(result) {
    // const image = result;
    const image = avatarImage;
    if (image.size < limitUpdateByte) {
      //imgnameの名前づけ(uid+imgname)
      let imgname = Date.now();
      const storage = getStorage(firebaseApp);
      //console.log(props.lonpaid);
      const storageRef = ref(
        storage,
        // "EventImage/" + imgname
        "YamanoteRoute/GatheringPlace" + imgname
      ); //どのフォルダの配下に入れるかを設定
      const uploadTask = uploadBytesResumable(storageRef, image); //ファイル名

      //ファイルをアップロードする
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // アップロードの進行状況を処理
        },
        (error) => {
          // アップロード中にエラーが発生した場合の処理
          console.error("アップロード中にエラーが発生しました: ", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //console.log(eki_id, shizi_id);
            const f = async () => {
              await updateGatheringPlace(eki_id, gatheringPlace_id, {
                imageURL: String(downloadURL),
              });
              setimageurl(downloadURL);
              //console.log(downloadURL);
            };

            f();
          });
        }
      );
    } else {
      alert("容量が大きすぎます。やり直してください");
    }
    // },
    // error(err) {
    //   console.log(err);
    //   return;
    // },
    // });
  }
  return (
    <>
      <img
        onClick={() => {
          fileUpload();
        }}
        src={imageurl}
        style={{
          minHeight: 80,
          minWidth: 80,
        }}
      />
      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={onSubmit}
      />
    </>
  );
};

export default ImageSettingGatheringPlace;
